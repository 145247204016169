import { Environment } from '@haulynx/types';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://web-api-stage.haulynx.io',
  envName: 'alpha',
  websocketUrl: 'wss://ws-gateway.haulynx.io/graphql',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiaGF1bHlueCIsImEiOiJja3ZzbjI1aWIwZXQwMzBrM3FqdW0xY3ZhIn0.2yIQI3nlR4iNrX12cRYjog',
    directionsApi: 'https://api.mapbox.com/directions/v5/mapbox/driving',
    drivingDirectionsApi: 'https://api.mapbox.com/directions/v5/mapbox/driving',
    timezoneApi: 'https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery',
    geocoding: {
      accessToken: 'pk.eyJ1IjoiaGF1bHlueCIsImEiOiJja3ZzbjI1aWIwZXQwMzBrM3FqdW0xY3ZhIn0.2yIQI3nlR4iNrX12cRYjog',
      geocodingApi: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    },
  },
  firebase: {
    apiKey: 'AIzaSyBEV_0HxGwt2kToshJhYtTIJvJ0DOGjhxg',
    authDomain: 'haulynx-elog-app.firebaseapp.com',
    databaseURL: 'https://haulynx-elog-app.firebaseio.com',
    projectId: 'haulynx-elog-app',
    storageBucket: 'haulynx-elog-app.appspot.com',
    messagingSenderId: '722208934797',
    appId: '1:722208934797:web:eabf966acb04f78448cbc4',
    measurementId: 'G-C2QE04P1Y6',
  },
  apollo: {
    url: '/api/graphql',
  },
  amplitude: {
    apiKey: '1a7d60c7eddf3d5c880648c2f688ba6f',
  },
  usx: {
    creatorId: 'eaRepWJ665bkbwbTMzhadxC5jPC3',
  },
  google: {
    mapsApi: 'AIzaSyAH2ixL6EHFOK9UF3FPYUTXVXgRdZ6iDD0',
  },
  elastic: {
    apmServerUrl: 'https://b512476d717b4298af9850d87c6f8f53.apm.us-west-2.aws.cloud.es.io/',
  },
  documents: {
    documentServerUrl: 'https://gateway.haulynx.io',
  },
};
